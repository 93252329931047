import {
  HttpBackend,
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/operators';
import { ServerConfigurations } from 'src/app/config';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private customHttpClient: HttpClient;
  headers: HttpHeaders = new HttpHeaders();
  errorMessage: string;
  constructor(public httpClient: HttpClient, backend: HttpBackend) {
    this.customHttpClient = new HttpClient(backend);
  }
  public getDashboard(id: any, indexId: any) {
    const apiBaseUrl =
      ServerConfigurations.BackendUrl + `getdashboard?dashid=${id}&indexPatternId=${indexId}`;
    return this.httpClient.get(apiBaseUrl, {responseType: 'text'})
    .pipe(catchError(this.handleError));
  }
  public getAlert(id: any) {
    const apiBaseUrl = ServerConfigurations.BackendUrl + `getalert?aid=${id}`;
    return this.httpClient.get(apiBaseUrl)
    .pipe(catchError(this.handleError));
  }
  public getUserInfo() {
    const httpHeaders = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + localStorage.getItem('id_token'),

        'Content-Type': 'application/json',
      }),
    };
    return this.customHttpClient.get(
      'https://sso.active-bytes.com/oauth2/userInfo',
      httpHeaders
    ) .pipe(catchError(this.handleError));
  }
  public getAlertScreenshot(id: any, screenshotId: any) {
    id = Number(id) + 0;
    const apiBaseUrl =
      ServerConfigurations.BackendUrl +
      `getalertscreenshot?aid=${id}&screen=${screenshotId}`;
    return this.httpClient.get(apiBaseUrl);
  }
  public logout() {
    localStorage.setItem('isLoggedIn', 'false');
    return this.login();
  }
  public listDashboards() {
    const apiBaseUrl = ServerConfigurations.BackendUrl + `listdashboards`;
    return this.httpClient.get(apiBaseUrl)
    .pipe(catchError(this.handleError));
  }
  public getDashboardScreenshot(id: any, screenShotId: any) {
    const apiBaseUrl =
      ServerConfigurations.BackendUrl +
      `getscreenshot?dashid=${id}&screen=${screenShotId}`;
    return this.httpClient.get(apiBaseUrl)
    .pipe(catchError(this.handleError));
  }
  public listAlerts() {
    const apiBaseUrl = ServerConfigurations.BackendUrl + `listalerts`;
    return this.httpClient.get(apiBaseUrl)
    .pipe(catchError(this.handleError));
  }
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
  public login() {
    const apiBaseUrl =
     ServerConfigurations.ServerUrl + '//logout?client_id=6c1u7u3cgfesn59cva5aog6tpj&logout_uri=' + ServerConfigurations.ServerUrl + '/logout&redirect_uri=' + ServerConfigurations.ServerUrl + '&response_type=token';
    return (
      this.httpClient
        .get(apiBaseUrl)

    );
  }
}
