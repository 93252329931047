import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { LoginService } from './services/login/login.service';
import { Component } from '@angular/core';
import {
  ActivatedRoute,
  NavigationCancel,
  Params,
  Router,
} from '@angular/router';
import { map } from 'rxjs/operators';

import * as _ from 'lodash';
import { NgEventBus } from 'ng-event-bus';
import { ServerConfigurations } from './config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  splitString = [];
  overlay: any = '';
  show: any;
  urlData = '';
  title = 'content-library';
  constructor(
    private loginService: LoginService,
    private route: ActivatedRoute,
    private router: Router,
    private dashboardService: DashboardService,
    private eventBus: NgEventBus
  ) {}
  ngOnInit(): void {
    this.eventBus.on('overlay').subscribe((overlayVal) => {
      this.overlay = overlayVal;
      setTimeout(() => {
        this.show = 'fade';
      }, 3000);
    });
    if (localStorage.getItem('isLoggedIn') == null) {
      localStorage.setItem('isLoggedIn', 'false');
    }
    const b = window.location.hash;
    const a = localStorage.getItem('isLoggedIn');
    if (window.location.hash){
    if (window.location.hash === '#/id_token'  ){
      if (localStorage.getItem('isLoggedIn') === 'false'){
      window.location.href = 'https://sso.active-bytes.com/login?response_type=token&client_id=6c1u7u3cgfesn59cva5aog6tpj&redirect_uri=' + ServerConfigurations.ServerUrl + '&scope=openid+profile';
      }
    }
    else
    {
      if (!!window.location.hash.toString().split('&')[1]){

      localStorage.setItem('id_token', window.location.hash.toString().split('&')[1].substring(13));
      localStorage.setItem(
        'id_token',
        window.location.hash.toString().split('&')[1].substring(13)

      );
      }
      const fragmentSection = window.location.hash.match(/^(.*?)&/);
      if (fragmentSection) {
        localStorage.setItem(
          'access_token',
          fragmentSection[1].substring(10)
        );
        localStorage.setItem('isLoggedIn', 'true');
        if (localStorage.getItem('onBoard') === null) {
          localStorage.setItem('onBoard', 'true');
          this.router.navigateByUrl('dashboard/dashboard-list');
          location.reload();
        }
      }}
    }

    this.route.fragment.subscribe((fragments) => {

      if (fragments) {
        if (fragments.toString().split('&')[1] != undefined) {
          localStorage.setItem(
            'id_token',
            fragments.toString().split('&')[1].substring(13)
          );
        }
      }
    });
    this.route.fragment
      .pipe(map((fragment) => fragment))
      .subscribe((fragment) => {
        if (fragment) {
          const fragmentSection = fragment.match(/^(.*?)&/);
          if (fragmentSection) {
            localStorage.setItem(
              'access_token',
              fragmentSection[1].substring(9)
            );
            localStorage.setItem('isLoggedIn', 'true');

            if (localStorage.getItem('onBoard') === null) {
              localStorage.setItem('onBoard', 'true');
              this.router.navigateByUrl('dashboard/dashboard-list');
              location.reload();
            }
            this.router.navigateByUrl('dashboard/dashboard-list');
          }
        }
      });
    if (localStorage.getItem('isLoggedIn') === 'false') {
      localStorage.setItem('access_token', 'false');
      this.dashboardService.login();
    } else {
      this.dashboardService.getUserInfo().subscribe(
        (data: any) => {
          localStorage.setItem('username', data.username);
        },
        (err) => {
            this.dashboardService.logout();
            this.router.navigateByUrl('');

        }
      );
    }
  }
}
