import { Injectable } from '@angular/core';
import { ServerConfigurations } from 'src/app/config';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
  HttpBackend,
} from '@angular/common/http';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/operators';
import {Http, RequestOptions, Headers, Response} from '@angular/http';
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  headers: HttpHeaders = new HttpHeaders();
  errorMessage: string;
  private customHttpClient: HttpClient;
  constructor(public httpClient: HttpClient, backend: HttpBackend) {
    this.customHttpClient = new HttpClient(backend);
  }
  public userLogin() {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    const apiBaseUrl =
      ServerConfigurations.LoginUrl +
      'login?response_type=token&client_id=6c1u7u3cgfesn59cva5aog6tpj&redirect_uri=' + ServerConfigurations.ServerUrl + '&scope=openid+profile';
    return this.customHttpClient
      .get(apiBaseUrl, {headers})
      .pipe(catchError(this.handleError));
  }
  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
