import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
// RouterModule.forRoot(routes,{
//   useHash: true
//   })
const routes: Routes = [
  {
    path: '', loadChildren: () =>
      import('./modules/home/home.module').then(
        (m) => m.HomeModule
      ),
  },
  {
    path: 'dashboard', loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),

  },
    {
    path: '**', loadChildren: () =>
      import('./modules/home/home.module').then(
        (m) => m.HomeModule
      ),

  },


];
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true
    })],
    // imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
