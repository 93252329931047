export const ServerConfigurations = {
  LoginUrl: 'https://sso.active-bytes.com/',
  BackendUrl: 'https://api.library.active-bytes.com/',
  DashboardModificationGuide1:
    'https://api.library.active-bytes.com/url/guide/guide1.pdf',
  DashboardImportGuide1:
    'https://api.library.active-bytes.com/url/guide/guide2.pdf',
  DashboardIndexPatternIdGuide1:
    'https://api.library.active-bytes.com/url/guide/guide3.pdf',
  AlertModificationGuide1:
    'https://api.library.active-bytes.com/url/guide/guide4.pdf',
  AlertImportGuide1:
    'https://api.library.active-bytes.com/url/guide/guide5.pdf',
  AlertIndexPatternIdGuide1:
    'https://api.library.active-bytes.com/url/guide/guide6.pdf',
  ServerUrl: 'https://library.active-bytes.com',
  //  ServerUrl:'http://localhost:4200',
  //  LoginUrl: 'http://localhost:4200/'
};
